<template>
    <div class="modal fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">Ultimo aggiornamento</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeButton'"></button>
                </div>
                
                <div class="modal-body" v-if="veicolo">
                    <div class="mb-3 bg-secondary text-white rounded p-3">
                        <span class="fw-bolder">ID gestionale</span>: {{ veicolo.id_gestionale }}<br>
                        <span class="fw-bolder">Telaio</span>: {{ veicolo.telaio }}<br>
                        <span class="fw-bolder">Targa</span>: {{ veicolo.targa }}<br>
                        <span class="fw-bolder">Marca</span>: {{ veicolo.marca }} <br>
                        <span class="fw-bolder">Modello</span>: {{ veicolo.modello }}<br>
                        </div>
                        <div class="mb-3">
                          <h4>Ultimo aggiornamento</h4>
                          <span class="fw-bolder">Stop orario</span>: {{ reformatDateTimeToRome(veicolo.lastEvent.timestamp) }}<br>
                          <span class="fw-bolder">Direzione</span>: {{ veicolo.lastEvent.direzione == '0' ? 'Entrata' : 'Uscita' }}<br>
                          <span v-if="veicolo.lastEvent.direzione == '1'"><span class="fw-bolder">Permanenza</span>: {{ formatTime(veicolo.lastEvent.permanenza) }} <br></span>
                          <span class="fw-bolder">Recinto</span>: {{ veicolo.lastEvent.recinto }} <br>
                          
                        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                </div>
              </div>
            </div>
          </div>
    </template>
    
    <script>    

    export default {
      name: 'PositionModal',
      props: ['id', 'veicolo'], //id used for modal idetnifier
      data(){
        return {
          
        }
      },
    
      methods: {
        formatTime(seconds) {
          let days = Math.floor(seconds / (24 * 3600));
          seconds %= 24 * 3600;
          let hours = Math.floor(seconds / 3600);
          seconds %= 3600;
          let minutes = Math.floor(seconds / 60);
          seconds %= 60;

          let parts = [];
          if (days > 0) parts.push(`${days} giorni`);
          if (hours > 0) parts.push(`${hours} ore`);
          if (minutes > 0) parts.push(`${minutes} minuti`);
          if (seconds > 0 || parts.length === 0) parts.push(`${seconds} secondi`);

          return parts.join(", ");
        },
      
        reformatDateTimeToRome(isoString) {
          let date = new Date(isoString);

          let hasTime = isoString.includes("T") && isoString.match(/\d{2}:\d{2}(:\d{2})?/);

          // Converte la data al fuso orario di Roma
          let options = { 
              timeZone: "Europe/Rome", 
              year: "numeric", 
              month: "2-digit", 
              day: "2-digit", 
          };

          if (hasTime) {
            options.hour = "2-digit";
            options.minute = "2-digit";
            options.second = "2-digit";
            options.hour12 = false;
          }

          // Ottiene la data nel formato locale italiano
          let formattedDate = new Intl.DateTimeFormat("it-IT", options).format(date);

          // Sostituisce i separatori per ottenere il formato desiderato
          return formattedDate.replace(",", "");
        }
      }
    }
    </script>
    